$services-height: auto;

// Desktop
@mixin services-desktop(){
	.services {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    top: -30px;
    z-index: -1;

    .par-container {
      width: 100%;

      .react-parallax-content {
        height: 100%;
      }
    }

    * {
      color: $color-white;
      letter-spacing: 2pt;
      text-transform: uppercase;
    }

    h1 {
      letter-spacing: 6pt;
      font-size: 50pt;
    }

    .services-bg {
      z-index: -2;

      img {
        width: 150%;
      }
    }

    .container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      
      z-index: 1;
      
      @include linear-gradient(darken(transparentize($blackColor, 0.7), 40), transparentize($blackColor, 0.4));
      //@include linear-gradient(transparentize($color-primary, 0.7), transparentize($color-primary, 0.4));
      
      .col {
        display: flex;
        flex-grow: 1;
        padding: 50px 60px;
        padding-top: 100px;

        color: $color-white;
      }
    }
  }
}

// Tablet_wide
@mixin services-tablet_wide(){
  .services {
    .services-bg {
      img {
        height: auto;
        width: 180%!important;
      }
    }
  }
}

// Tablet
@mixin services-tablet(){
  .services {
    .services-bg {
      img {
        height: auto;
        width: 210%!important;
      }
    }
    .container {
      .col {
        padding-top: 50px;
      }
    }
  }
}

// Phone_wide
@mixin services-phone_wide(){
	.services {
    height: $services-height / 2 + 70;
    transform: rotate(0deg);
    p {
      text-align: center;
    }
    h1 {
      font-size: 30pt;
      text-align: center;
    }
    h2 {
      font-size: 13pt;
      text-align: center;
    }
    .services-bg {
      transform: rotate(0deg);
      
      img {
        height: auto;
      }
    }
    .container {
      .col {
        transform: rotate(0deg);
        padding-top: 50px;
      }
    }
  }
}

// Phone
@mixin services-phone(){
	.services {

    h1 {
      font-size: 30pt;
    }
  }
}
// Desktop
@mixin footer-desktop(){
  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    padding-top: 60px;

    div.col {
      h4 {
        text-transform: uppercase;
        font-size: 15pt;
        font-weight: 500;
      }
    }

    background-color: $blackColor;

    * {
      color: $color-white;
    }
  }
}

// Tablet_wide
@mixin footer-tablet_wide(){

}

// Tablet
@mixin footer-tablet(){
  footer {
    flex-direction: column;
  
    .col {
      iframe {
        text-align: center;
      }
    }
  }
}

// Phone_wide
@mixin footer-phone_wide(){
}

// Phone
@mixin footer-phone(){
	
}
/* Import variables */
@import "vars/__inc";

/* Import mixins */
@import "mixins/__inc";
//@import "partials/__import";

@import "partials/width";
@import "partials/_sizes";
@import "partials/_colors";

//@include reinit;

/* Import modules */
@import "modules/__inc";

/* Import views */
@import "views/__import";

/* Import vendors */
@import "vendors/__import";

/**
 * Media Queries Order
 * - Desktop		(1200px, not restricted)
 * - Tablet_wide	(1024px & lower)
 * - Tablet 		(768px & lower)
 * - Phone_wide 	(570px & lower)
 * - Phone 			(320px & lower)
 **/

// Desktop
@include width-desktop;

@include header-desktop;
@include banner-desktop;
@include services-desktop;
@include abo-desktop;
@include main-desktop;
@include card-desktop;
@include pages-desktop;
@include footer-desktop;

// @include view-desktop;

// Tablet_wide
@media screen and (max-width:$breakpoint-tablet_wide) {
 @include width-tablet_wide;

 @include banner-tablet_wide;
 @include services-tablet_wide;
 @include header-tablet_wide;
 @include main-tablet_wide;
 @include pages-tablet_wide;
 @include footer-tablet_wide;
 @include card-tablet_wide;
 
 // @include view-tablet_wide;
}
// Tablet
@media screen and (max-width:$breakpoint-tablet) {
 @include width-tablet;
 @include banner-tablet;
 @include services-tablet;
 @include header-tablet;
 @include main-tablet;
 @include pages-tablet;
 @include footer-tablet;
 @include abo-tablet;
 @include card-tablet;
 
 // @include view-tablet;
}
// Phone_wide
@media screen and (max-width:$breakpoint-phone_wide) {
 @include width-phone_wide;
 @include banner-phone_wide;
 @include services-phone_wide;
 @include header-phone_wide;
 @include main-phone_wide;
 @include pages-phone_wide;
 @include footer-phone_wide;
 @include card-phone_wide;
 
 // @include view-phone_wide;
}
// Phone
@media screen and (max-width:$breakpoint-phone) {
  @include width-phone;
  
  @include header-phone;
  @include banner-phone;
  @include services-phone;
  @include main-phone;
  @include pages-phone;
  @include footer-phone;
  @include card-phone;
 
 // @include view-phone;
}
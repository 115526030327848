$banner-height: 500px;

// Desktop
@mixin banner-desktop(){
  .ultra {
    text-shadow: 1px 1px 1px #e41737, 2px 2px 1px #fff, 3px 3px 1px #e41737, 4px 4px 1px #fff, 5px 5px 1px #e41737, 6px 6px 10px rgba(0, 0, 0, 0.6);
    margin-bottom: 5px;
    width: 90%;
    text-align: center;
  }
  .sideline {
    position: relative;
  
    &:before, &:after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 25%;
      background-color: #fff;
      top: 50%;
      box-shadow: 1px 1px 1px #e41737, 6px 6px 10px rgba(0, 0, 0, 0.6);
    }
  
    &:before {
      left: 12.5%;
    }
  
    &:after {
      right: 12.5%;
    }
  }

	.banner {
    display: flex;
    flex-grow: 1;
    height: $banner-height + 10;
    position: relative;
    overflow: hidden;
    width: 110%;
    top: -30px;
    transform: rotate(2deg);
    z-index: 1;
    left: -10px;

    .banner-img {
      height: $banner-height - 200;
    }

    .par-img {
      top: -40%;
    }

    .par-container {
      width: 100%;

      .react-parallax-content {
        height: 100%;
      }
    }
    

    * {
      color: $color-white;
      letter-spacing: 2pt;
      text-transform: uppercase;
    }

    h1 {
      letter-spacing: 6pt;
      font-size: 50pt;
    }

    .banner-bg {
      z-index: -2;
      transform: rotate(-2deg);

      img {
        width: 150%;
      }
    }

    .container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      
      z-index: 1;
      
      @include linear-gradient(darken(transparentize($blackColor, 0.7), 40), transparentize($blackColor, 0.4));
      //@include linear-gradient(darken(transparentize($color-primary, 0.7), 40), transparentize($color-primary, 0.4));
      
      .col {
        transform: rotate(-2deg);
        display: flex;
        flex-grow: 1;
        padding: 50px 60px;
        padding-top: 100px;

        color: $color-white;
      }
    }
  }
}

// Tablet_wide
@mixin banner-tablet_wide(){
  .banner {
    .banner-bg {
      img {
        height: auto;
        width: 180%!important;
      }
    }
  }

  .sideline {
    position: relative;
  
    &:before, &:after {
      width: 15%;
    }
  }
}

// Tablet
@mixin banner-tablet(){
  .banner {
    .banner-img {
      padding-top: 100px;
      height: $banner-height - 300;
    }

    .banner-bg {
      img {
        height: auto;
        width: 210%!important;
      }
    }
    .container {
      .col {
        padding-top: 50px;
        padding-left: 30px;
      }
    }
  }

  .sideline {
    position: relative;
  
    &:before, &:after {
      width: 15%;
    }

    &:before {
      left: 0;
    }
  
    &:after {
      right: 0%;
    }
  }
}

// Phone_wide
@mixin banner-phone_wide(){
	.banner {
    height: $banner-height / 2 + 70;
    transform: rotate(0deg);

    .banner-img {
      padding-top: 30px;
      height: 100px;
    }

    .ultra {
      padding-top: -10px;
      margin-top: 0px;
      margin-left: -10px;
    }

    p {
      text-align: center;
    }
    h1 {
      font-size: 30pt;
      text-align: center;
    }
    h2 {
      font-size: 13pt;
      text-align: center;
    }
    .banner-bg {
      transform: rotate(0deg);
      
      img {
        height: auto;
      }
    }
    .container {
      .col {
        transform: rotate(0deg);
        padding-top: 50px;
      }
    }
  }
}

// Phone
@mixin banner-phone(){
	.banner {
    .banner-img {
      height: 100px;
    }

    h1 {
      font-size: 30pt;
    }
    .banner-bg {
      img {
      }
    }
    .container {
      .col {
        padding-left: 5px;
      }
    }
  }
}
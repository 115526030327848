@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,900);
/* Import variables */
/* Import variables */
/* Breakpoints */
/* Max-Width in current breakpoint */
/************** COLORS **************/
/************ Base ************/
/* White */
/* Grey */
/* Black */
/************ Graphic ************/
/* Header */
/* Main */
/* Tables */
/* Footer */
/************ Fonts ************/
/* Headings */
/* Paragraphs */
/* Links */
/************ Buttons ************/
/************ Form ************/
/************ Specific ************/
/* Set base font degradation */
@-webkit-keyframes first_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); }
  100% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); } }
@keyframes first_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); }
  100% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); } }

@-webkit-keyframes second_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  33% {
    -webkit-transform: scaleY(0.5) scaleX(0.9) rotate(90deg);
            transform: scaleY(0.5) scaleX(0.9) rotate(90deg); }
  100% {
    -webkit-transform: scaleY(0.5) scaleX(0.9) rotate(90deg);
            transform: scaleY(0.5) scaleX(0.9) rotate(90deg); } }

@keyframes second_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  33% {
    -webkit-transform: scaleY(0.5) scaleX(0.9) rotate(90deg);
            transform: scaleY(0.5) scaleX(0.9) rotate(90deg); }
  100% {
    -webkit-transform: scaleY(0.5) scaleX(0.9) rotate(90deg);
            transform: scaleY(0.5) scaleX(0.9) rotate(90deg); } }

@-webkit-keyframes third_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); }
  100% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); } }

@keyframes third_burger_bar {
  0% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1); }
  17% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); }
  100% {
    -webkit-transform: scaleY(1.9) scaleX(0.9);
            transform: scaleY(1.9) scaleX(0.9); } }

@-webkit-keyframes menu_sticky {
  0% {
    top: -300px; }
  100% {
    top: 0px; } }

@keyframes menu_sticky {
  0% {
    top: -300px; }
  100% {
    top: 0px; } }

@-webkit-keyframes menu_disappear {
  0% {
    top: 0px; }
  100% {
    top: -300px; } }

@keyframes menu_disappear {
  0% {
    top: 0px; }
  100% {
    top: -300px; } }

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

/* Import mixins */
/* Import locals mixins */
/* border-radius mixins */
/* Import locals mixins */
* {
  font-family: 'Montserrat', sans-serif;
  color: #2d2d2d; }

html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #2d2d2d;
  z-index: -1; }

a {
  text-decoration: none; }

#App {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1; }
  #App.ready {
    opacity: 1; }
  #App .column {
    -webkit-flex-direction: column;
            flex-direction: column; }

/* Import globals mixins */
/* @doc
	<name>animation</name>
	<desc>Animates the element using keyframes.</desc>
	<example>
		.myButton{
			@include animation($example, .8s, 3s);
		}
	</example>
	<output>
		.myButton{
			-webkit-animation: example .8s 3s;
			-moz-animation: example .8s 3s;
			-ms-animation: example .8s 3s;
			-o-animation: example .8s 3s;
			animation: example .8s 3s;
		}
	</output>
*/
/*
	@doc
	<name>keyframes</name>
	<desc>Creates an animation to then use with the animation mixin.</desc>
	<example>@include keyframes(example){ ... };
	</example>
	<output>@keyframes example { ... };</output>
*/
/*
	@doc
	<name>transition</name>
	<desc>Adds or removes a transition.</desc>
	<example>
		.myButton{
			@include transition(width, .5s, ease);
		}
	</example>
	<output>
		.myButton{
			-webkit-transition: width .5s ease;
			-moz-transition: width .5s ease; 
			...
		}
	</output>
*/
/*
	@doc
	<name>noTransition</name>
	<desc>Removes a transition.</desc>
	<example>
		.myClass{
			@include noTransition;
		}
	</example>
	<output>
		.myClass{
			-webkit-transition: 0;
			-moz-transition: 0;
			-ms-transition: 0;
			-o-transition: 0;
			transition: 0;
		}
	</output>
*/
/*
	@doc
	<name>clearfix</name>
	<desc>Simple clearfix.</desc>
	<example>@include clearfix();</example>
	<output>
		.clearfix{ 
			content:""; 
			display:table;
			clear:both;
		}	
	</output>
*/
/*
	@doc
	<name>display_show</name>
	<desc>Changes the display to show an element.</desc>
	<example>@include show(block);</example>
	<output>
		#myDiv{
			display:block;
		}
	</output>
*/
/*
	@doc
	<name>display_hide</name>
	<desc>Changes the display to hide an element.</desc>
	<example>@include hide()</example>
	<output>
		.myClass{
			display:none;
		}
	</output>
*/
/*
	@doc
	<name>noOverflow</name>
	<desc>Hides the overflow</desc>
	<example>@include noOverflow();</example>
	<output>
		#wrapper{
			overflow: hidden;
		}
	</output>
*/
/*
	@doc
	<name>z-index</name>
	<desc>Receives a section & optionnal additional value to set its index.</desc>
	<example>@include z-index(overall,55);</example>
	<output>
		.myTopElement{
			z-index:1155;
		}
	</output>
*/
/*
	@doc
	<name>background_rollover</name>
	<desc>Changes the background if rolled over or hovered</desc>
	<example>
		#myDiv{
			@include background(example, rl, center top no-repeat gif);
		}
	</example>
	<output>
		#mySection{
			background: url(img/example_up.gif) center top no-repeat; 
			&:hover { 
				background: url(img/example_rl.gif) center top no-repeat;  
			}
		}
	</output>
*/
/*
	@doc
	<name>background_slideEffect</name>
	<desc>Adds a slide effect to the background.</desc>
	<example>
		#myDiv{
			@include backgroundSlideEffect(example, 100px, .2s, #000);
		}
	</example>
	<output>
		#myDiv{
			position: relative;
			line-height: $distance;
			span{
				display: inline-block;
				position: relative;
				overflow: hidden;
				@include hideText;
				&:before, &:after{
					color: $color;
					position: absolute;
					top: 0;
					left:0;
					content: attr(data-content);
					@include background($image);
					@include transition(top, $speed);
				}
				&:after{
					top: $distance;
				}
			}
			&:hover{
				span{
					&:before{
						top: $distance * -1;
					}
					&:after{
						top: 0px;
					}
				}
			}
		}
	</output>
*/
/*
	@doc
	<name>border</name>
	<desc>Adds a border to the specified side(s). (all if none specified)</desc>
	<example>
		.myClass{
			@include border(#888, bottom, 2px, solid);
		}
	</example>
	<output>
		.myClass{
			border-bottom: #888 2px solid;
		}
	</output>
*/
/*
	@doc
	<name>border_noBorder</name>
	<desc>Removes a border to the specified side(s). (all if none specified)</desc>
	<example>
		.myClass{
			@include noBorder(bottom);
		}
	</example>
	<output>
		.myClass{
			border-bottom: 0;
		}
	</output>
*/
/*
	@doc
	<name>background_rollover</name>
	<desc>Changes the background if rolled over or hovered</desc>
	<example>
		#myDiv{
			@include background(example, rl, center top no-repeat gif);
		}
	</example>
	<output>
		#mySection{
			background: url(img/example_up.gif) center top no-repeat; 
			&:hover { 
				background: url(img/example_rl.gif) center top no-repeat;  
			}
		}
	</output>
*/
/*
	@doc
	<name>border-radius</name>
	<desc>Adds a radius to the borders (Default : 5px).</desc>
	<example>
		.myClass{
			@include border-radius;
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 5px; 
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			border-radius: 5px;
		}
	</output>
	<example>
		.myClass{
			@include border-radius(10px);
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 10px; 
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			-o-border-radius: 10px;
			border-radius: 10px;
		}
	</output>
*/
/*
	@doc
	<name>border-radius_sides</name>
	<desc>Adds a radius to the borders (Default : 5px).</desc>
	<example>
		.myClass{
			@include border-radius;
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 5px; 
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			border-radius: 5px;
		}
	</output>
	<example>
		.myClass{
			@include border-radius(10px);
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 10px; 
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			-o-border-radius: 10px;
			border-radius: 10px;
		}
	</output>
*/
/*
	@doc
	<name>border-radius_side</name>
	<desc>Adds a radius to the borders (Default : 5px).</desc>
	<example>
		.myClass{
			@include border-radius;
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 5px; 
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			border-radius: 5px;
		}
	</output>
	<example>
		.myClass{
			@include border-radius(10px);
		}
	</example>
	<output>
		.myClass{
			-webkit-border-radius: 10px; 
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			-o-border-radius: 10px;
			border-radius: 10px;
		}
	</output>
*/
/*
	@doc
	<name>box-shadow</name>
	<desc>Adds a box-shadow to the element (Cross-Browser Compatibility).</desc>
	<example>
		.myClass{
			@include box-shadow(8px 8px 5px #777 false);
		}
	</example>
	<output>
		.myClass{
			-webkit-box-shadow: 8px 8px 4px #777; 
			-moz-box-shadow: 8px 8px 4px #777;
			box-shadow: 8px 8px 4px #777;
		}
	</output>
*/
/*
	@doc
	<name>button-3D</name>
	<desc>Adds a cubic/3D effect to the element. Mostly for buttons, but could be used in other ways!</desc>
	<example>
		.myClass{
			@include button3D();
		}
	</example>
	<output>
		.myClass{
			
		}
	</output>
*/
/*
	@doc
	<name>filter</name>
	<desc>Adds a CSS filter (Cross-Browser Compatibility).</desc>
	<example>
		.myClass{
			@include(grayscale, 100%);
		}
	</example>
	<output>
		.myClass{
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			-ms-filter: grayscale(100%);
			-o-filter: grayscale(100%);
			filter: grayscale(100%);
		}
	</output>
*/
/*
	@doc
	<name>outline</name>
	<desc>Adds an outline to the element.</desc>
	<example>
		.myClass{
			@include outline(#454545, all, 2px, solid);
		}
	</example>
	<output>
		.myClass{
			outline: #454545 2px solid;
		}
	</output>
*/
/*
	@doc
	<name>rotate</name>
	<desc>Rotates the element(Cross-Browser Compatibility).</desc>
	<example>
		.myClass{
			@include rotate(45);
		}
	</example>
	<output>
		.myClass{
			-webkit-transform:rotate(45);
			-moz-transform:rotate(45);
			-ms-transform:rotate(45);
			-o-transform:rotate(45);
			transform:rotate(45);
		}
	</output>
*/
/*
	@doc
	<name>sprite</name>
	<desc>Styles a sprite..</desc>
	<example>
		.myButton{
			@include sprite(50px, 24px, 24px);
		}
	</example>
	<output>
		.myButton{
			background: url('../img/icons1.png') 0 50px no-repeat; 
			height: 24px;
			width: 24px;
			display: inline-block;
		}
	</output>
*/
/*
	@doc
	<name>breakpoints</name>
	<desc>Replaces the "@media screen and (...)" by a simple word.</desc>
	<example>@include phone{ ... };</example>
	<output>
		@media only screen and (max-width: 480){ 
			... 
		}
	</output>
*/
/*
	@doc
	<name>margin</name>
	<desc>Adds margins to specified side(s) (all if none specified)</desc>
	<example>@include margin(5px, left)</example>
	<output>
		.myClass{
			margin-left: 5px;
		}
	</output>
*/
/*
	@doc
	<name>margin_noMargin</name>
	<desc>Removes margins of specified side(s) (all if none specified)</desc>
	<example>@include noMargin(left)</example>
	<output>
		.myClass{
			margin-left: 0;
		}
	</output>
*/
/*
	@doc
	<name>padding</name>
	<desc>Adds paddings to specified side(s) (all if none specified)</desc>
	<example>@include padding(5px, left)</example>
	<output>
		.myClass{
			padding-left: 5px;
		}
	</output>
*/
/*
	@doc
	<name>padding_noPadding</name>
	<desc>Removes paddings of specified side(s) (all if none specified)</desc>
	<example>@include noPadding(left)</example>
	<output>
		.myClass{
			padding-left: 0;
		}
	</output>
*/
/*
	@doc
	<name>stickyFooter</name>
	<desc>Makes the footer sticky.</desc>
	<example>@include stickyFooter(250px, 0px);</example>
	<output>{ ... };</output>
*/
/*
	@doc
	<name>font-face</name>
	<desc>Receives $font-family(given name) & $font-filename(file).</desc>
	<example>@include font-face(Open Sans, open-sans, bold);</example>
	<output>
		#mySection h1{
			font-family: Open Sans; 
			src: url(fonts/open-sans.ttf); 
			...;
		}
	</output>
*/
/*
	@doc
	<name>text_hide</name>
	<desc>Simply hides the text.</desc>
	<example>@include hideText();</example>
	<output>
		#mySection p{
			text-indent: -9999px;
		}
	</output>
*/
/*
	@doc
	<name>text_slide</name>
	<desc>Sliding effect for texts.</desc>
	<example>@include textSlideEffect(5px, .3s, #000, bottom);</example>
	<output>
		#mySection p{
			position: relative;
			line-height: 5px;
			span{
				display: inline-block;
				position: relative;
				overflow: hidden;
				color: transparent;
				&:before, &:after{
					color: #000;
					position: absolute;
					top: 0;
					left:0;
					content: attr(data-content);
					@include transition(bottom, .3s);
				}
				&:after{
					bottom: 5px * 1;
				}
			}
			&:hover{
				span{
					&:before{
						bottom: 5px * -1 * 1;
					}
					&:after{
						bottom: 0px;
					}
				}
			}
		}
		</output>
*/
/*
	@doc
	<name>contained mixins</name>
	<desc>Contained mixins</desc>
	<example>@include contained($selector, $type);</example>
	<example>@include contained(no-js, class);</example>
	<output>
		.no-js .class{ 
			@content;
		}
	</output>
*/
/*	@doc
	<name>prefixer</name> 
	<desc>Adds browsers' different prefixes when needed. (doc. http://bourbon.io/docs/#prefixer)</desc>
	<example>@include prefixer(transform, rotate(5deg));</example>
	<output>
		.myElement{
			-webkit-transform: rotate(5deg);
			-moz-transform: rotate(5deg);
			-ms-transform: rotate(5deg);
			--transform: rotate(5deg);
			transform: rotate(5deg);
		}
	</output>
*/
/*	@doc
	<name>dev-mode</name> 
	<desc>Add to to help developper develop</desc>
	<example>@include dev-mode(on)</example>
	<output>
	</output>
*/
/*	@doc
	<name>reinit</name> 
	<desc>Reinit base css</desc>
	<example>@include reinit</example>
	<output>
	</output>
*/
.full-width {
  width: 100%;
  max-width: none;
  max-width: initial; }

.full-height {
  height: 100%;
  max-height: none;
  max-height: initial; }

/**
 * ! Don't edit underneath
 * - Shade of the base colors are generated
 * - Shade of the greys are generated
 * 
 * - All available colors & greys shown at the bottom
 **/
/*
 * Example of all available shades of colors
 */
.colors {
  color: #e4e4e4;
  color: #c5c5c5;
  color: #a7a7a7;
  color: #888888;
  color: dimgray;
  color: #4b4b4b;
  color: #2c2c2c;
  color: #f8bbc5;
  color: #f38495;
  color: #ed4c65;
  color: #e51737;
  color: #ad112a;
  color: #760c1c;
  color: #3e060f;
  color: #b3b5b6;
  color: #949699;
  color: #75787a;
  color: #57595b;
  color: #393a3c;
  color: #1b1c1c;
  color: black; }

/*
 * Example of all available shades of greys
 */
.greys {
  color: #ffffff;
  color: #e0e0e0;
  color: #c2c2c2;
  color: #a3a3a3;
  color: gainsboro;
  color: #bdbdbd;
  color: #9f9f9f;
  color: #808080;
  color: #616161;
  color: #434343;
  color: #242424;
  color: #5c5c5c;
  color: #3d3d3d;
  color: #1f1f1f;
  color: #000000; }

/* Import modules */
/* Import variables */
/* Import views */
/**
 * pages can differ from project to project
 * Base view file to duplicate for every new view
 * - Duplicate file
 * - Select "@mixin view"
 * - Replace by "@mixin new_view_name"
 * - Save & enjoy !
 * 
 * ! Don't forget to the same in the style.scss (@media)
 * ! Don't forget include your new file in the folder's __import.scss
 **/
/**
 * View can differ from project to project
 * Base view file to duplicate for every new view
 * - Duplicate file
 * - Select "@mixin view"
 * - Replace by "@mixin new_view_name"
 * - Save & enjoy !
 * 
 * ! Don't forget to the same in the style.scss (@media)
 * ! Don't forget include your new file in the folder's __import.scss
 **/
/* Import vendors */
/**
 * Vendors are used usually with a script
 * Eg: animate.css when using wow.js
 * 
 * - Just paste the code for every vendor in a different file
 **/
/**
 * Media Queries Order
 * - Desktop		(1200px, not restricted)
 * - Tablet_wide	(1024px & lower)
 * - Tablet 		(768px & lower)
 * - Phone_wide 	(570px & lower)
 * - Phone 			(320px & lower)
 **/
.website-width {
  width: 97%;
  max-width: 1200px;
  margin: 0 auto;
  transition: width 0.25s ease;
  position: relative; }

.website-half-width {
  width: 97%;
  max-width: 600px;
  margin: 0 auto;
  transition: width 0.25s ease; }

header {
  height: 98px;
  background-color: #2d2d2d;
  z-index: 99;
  opacity: 1;
  transition: all 0.6s ease-in-out; }
  header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    height: 78px;
    background-color: #ffffff;
    border-bottom: 2px solid #2d2d2d;
    -webkit-animation: 0.6s ease-in-out menu_sticky;
            animation: 0.6s ease-in-out menu_sticky; }
    header.sticky * {
      color: #2d2d2d !important; }
    header.sticky .burger {
      background-color: #ffffff !important;
      border-color: #2d2d2d !important; }
      header.sticky .burger span {
        background-color: #2d2d2d !important; }
    header.sticky #nav-main {
      top: 78px;
      border-left: 2px solid #2d2d2d; }
  header nav ul {
    list-style: none; }
    header nav ul a {
      transition: all 0.25s ease; }
    header nav ul a.active {
      color: #e51737 !important; }
    header nav ul.side-nav li {
      padding: 20px 0px; }
      header nav ul.side-nav li * {
        color: #ffffff !important; }
  header.will-disappear {
    -webkit-animation: 0.6s ease-in-out menu_disappear;
            animation: 0.6s ease-in-out menu_disappear; }
  header .link-24 {
    color: #ffffff; }
  header input[type=checkbox] {
    position: absolute;
    visibility: hidden; }
  header input[type=checkbox]:checked + .menu-container #nav-main {
    display: block;
    right: 0px; }
  header input[type=checkbox]:checked + .menu-container .menu .burger {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(1) {
      -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px);
              transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px); }
    header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(2) {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(3) {
      -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(0.5px);
              transform: scaleY(2.1) scaleX(0.9) translateY(0.5px); }
  header #nav-main {
    position: absolute;
    width: 400px;
    right: -100%;
    top: 98px;
    background-color: #ffffff;
    box-shadow: none;
    padding: 20px;
    z-index: 99;
    transition: all 0.25s ease; }
  header .menu-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%; }
    header .menu-container .menu {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      width: 90%;
      max-width: 1500px;
      margin: auto; }
      header .menu-container .menu div {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
        header .menu-container .menu div:nth-child(1) {
          -webkit-justify-content: flex-start;
                  justify-content: flex-start; }
          header .menu-container .menu div:nth-child(1) img {
            height: 68px; }
        header .menu-container .menu div:nth-child(2) {
          -webkit-justify-content: center;
                  justify-content: center; }
        header .menu-container .menu div:nth-child(3) {
          -webkit-justify-content: flex-end;
                  justify-content: flex-end; }
      header .menu-container .menu nav {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        color: gray;
        font-family: "open sans", sans-serif;
        font-size: 15px;
        font-weight: 400; }
        header .menu-container .menu nav ul {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: space-evenly;
                  justify-content: space-evenly;
          padding-left: 0px;
          list-style-image: none;
          list-style-position: outside;
          list-style-type: none; }
          header .menu-container .menu nav ul li {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            -webkit-justify-content: center;
                    justify-content: center; }
            header .menu-container .menu nav ul li a {
              font-weight: 700;
              font-size: 13px;
              letter-spacing: 1.3px;
              text-transform: uppercase;
              color: #ffffff; }
            header .menu-container .menu nav ul li a {
              cursor: pointer;
              transition: all 0.25s ease; }
              header .menu-container .menu nav ul li a:hover {
                color: #e6e6e6; }
              header .menu-container .menu nav ul li a:active {
                color: #b7122c; }
      header .menu-container .menu .burger {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        height: 32.66667px;
        width: 32.66667px;
        border: 2.7px solid #ffffff;
        border-radius: 7px;
        cursor: pointer;
        transition: all 0.25s ease; }
        header .menu-container .menu .burger span {
          height: 3px;
          width: 16.33333px;
          background-color: #ffffff;
          margin: 2px auto;
          transition: all 0.25s ease; }
        header .menu-container .menu .burger:hover span:nth-child(1) {
          -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px);
                  transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px); }
        header .menu-container .menu .burger:hover span:nth-child(2) {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
        header .menu-container .menu .burger:hover span:nth-child(3) {
          -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(0.5px);
                  transform: scaleY(2.1) scaleX(0.9) translateY(0.5px); }

.btn {
  background-color: #e51737;
  border: none;
  padding: 12px 25px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 20px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s ease; }
  .btn:hover {
    background-color: #c0132e; }

.ultra {
  text-shadow: 1px 1px 1px #e41737, 2px 2px 1px #fff, 3px 3px 1px #e41737, 4px 4px 1px #fff, 5px 5px 1px #e41737, 6px 6px 10px rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  width: 90%;
  text-align: center; }

.sideline {
  position: relative; }
  .sideline:before, .sideline:after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 25%;
    background-color: #fff;
    top: 50%;
    box-shadow: 1px 1px 1px #e41737, 6px 6px 10px rgba(0, 0, 0, 0.6); }
  .sideline:before {
    left: 12.5%; }
  .sideline:after {
    right: 12.5%; }

.banner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 510px;
  position: relative;
  overflow: hidden;
  width: 110%;
  top: -30px;
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
  z-index: 1;
  left: -10px; }
  .banner .banner-img {
    height: 300px; }
  .banner .par-img {
    top: -40%; }
  .banner .par-container {
    width: 100%; }
    .banner .par-container .react-parallax-content {
      height: 100%; }
  .banner * {
    color: #ffffff;
    letter-spacing: 2pt;
    text-transform: uppercase; }
  .banner h1 {
    letter-spacing: 6pt;
    font-size: 50pt; }
  .banner .banner-bg {
    z-index: -2;
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); }
    .banner .banner-bg img {
      width: 150%; }
  .banner .container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    z-index: 1;
    background: rgba(45, 45, 45, 0.6);
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(45, 45, 45, 0.6)); }
    .banner .container .col {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      padding: 50px 60px;
      padding-top: 100px;
      color: #ffffff; }

.services {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  position: relative;
  overflow: hidden;
  top: -30px;
  z-index: -1; }
  .services .par-container {
    width: 100%; }
    .services .par-container .react-parallax-content {
      height: 100%; }
  .services * {
    color: #ffffff;
    letter-spacing: 2pt;
    text-transform: uppercase; }
  .services h1 {
    letter-spacing: 6pt;
    font-size: 50pt; }
  .services .services-bg {
    z-index: -2; }
    .services .services-bg img {
      width: 150%; }
  .services .container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    z-index: 1;
    background: rgba(45, 45, 45, 0.6);
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(45, 45, 45, 0.6)); }
    .services .container .col {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      padding: 50px 60px;
      padding-top: 100px;
      color: #ffffff; }

::selection {
  background: #e51737;
  color: #ffffff; }

.abo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  background-color: #ffffff; }
  .abo .success-bordered {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 4px solid green;
    height: 100px;
    width: 100px;
    border-radius: 100%; }
  .abo .failed-bordered {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 4px solid #e51737;
    height: 100px;
    width: 100px;
    border-radius: 100%; }
  .abo .spinner {
    font-size: 20px;
    width: 1em;
    height: 1em;
    margin: 10px auto;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.1em rgba(229, 23, 55, 0.1); }
  .abo .spinner i {
    position: absolute;
    clip: rect(0, 1em, 1em, 0.5em);
    width: 1em;
    height: 1em;
    -webkit-animation: spinner-circle-clipper 1s ease-in-out infinite;
            animation: spinner-circle-clipper 1s ease-in-out infinite; }

@-webkit-keyframes spinner-circle-clipper {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@keyframes spinner-circle-clipper {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }
  .abo .spinner i:after {
    position: absolute;
    clip: rect(0, 1em, 1em, 0.5em);
    width: 1em;
    height: 1em;
    content: '';
    -webkit-animation: spinner-circle 1s ease-in-out infinite;
            animation: spinner-circle 1s ease-in-out infinite;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.1em #e51737; }

@-webkit-keyframes spinner-circle {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@keyframes spinner-circle {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }
  .abo .form-container p {
    max-width: 80%; }
  .abo .form {
    max-width: 500px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 30px 20px 10px 20px;
    border: 2px solid #2d2d2d; }
    .abo .form .form-header {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #2d2d2d; }
    .abo .form .form-footer {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid #2d2d2d; }
    .abo .form .form-body {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      padding: 20px 0px 30px 0px; }
      .abo .form .form-body *:focus {
        outline: none; }
      .abo .form .form-body label {
        font-size: 10pt;
        margin-bottom: 10px; }
      .abo .form .form-body input {
        border: none;
        border-bottom: 2px solid #2d2d2d;
        border-radius: 0;
        font-size: 12pt;
        width: 100%;
        transition: all 0.25s ease; }
        .abo .form .form-body input.invalid {
          border-color: #e51737; }
      .abo .form .form-body .form-row {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin: 20px 0px; }
      .abo .form .form-body .group-col {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
  .abo div {
    display: -webkit-flex;
    display: flex; }
    .abo div.title {
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .abo div.title h3.strong {
        font-size: 30pt;
        font-weight: 700;
        letter-spacing: 2pt; }
  .abo h3 {
    text-transform: uppercase;
    letter-spacing: 1.5pt; }

.cards {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .cards .star {
    font-size: 14pt;
    color: #ffffff;
    vertical-align: text-top;
    font-weight: 300; }
  .cards .card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 90%;
    max-width: 450px;
    min-height: 600px;
    background-color: #2d2d2d;
    margin: 0px 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease; }
    .cards .card span.line-through {
      position: relative;
      color: #ffffff;
      font-weight: 500;
      font-size: 12pt; }
      .cards .card span.line-through:after {
        position: absolute;
        content: ' ';
        left: -1px;
        top: 10px;
        height: 1px;
        width: 100%;
        background-color: #ffffff; }
    .cards .card .promo {
      position: absolute;
      top: -30px;
      right: -220px;
      background-color: #ffc754;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      padding: 60px 200px 0px 200px; }
    .cards .card .corner {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      background-color: #e51737;
      width: 100%;
      padding: 10px 0px; }
      .cards .card .corner h3 {
        text-align: center;
        padding: 5px;
        margin: 5px; }
    .cards .card:hover {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px); }
    .cards .card * {
      outline: none;
      border: none; }
    .cards .card button {
      background-color: #e51737;
      border: none;
      padding: 12px 25px;
      font-size: 12px;
      font-weight: 700;
      border-radius: 20px;
      color: #ffffff;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.25s ease; }
      .cards .card button:hover {
        background-color: #c0132e; }
    .cards .card .info {
      color: #ffffff;
      font-size: 10px;
      -webkit-align-self: flex-end;
              align-self: flex-end;
      margin: 10px; }
    .cards .card ul {
      list-style: none;
      padding-left: 0px;
      width: 100%; }
      .cards .card ul li {
        color: #ffffff;
        text-align: center;
        padding: 20px 12px;
        border-bottom: 1px solid #494949;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2.4px;
        text-transform: uppercase; }
        .cards .card ul li h4 {
          color: #ffffff; }
        .cards .card ul li:nth-child(1) {
          font-size: 30pt;
          font-weight: 500; }
          .cards .card ul li:nth-child(1) h4 {
            margin: 18px; }
          .cards .card ul li:nth-child(1) p {
            color: #ffffff;
            font-size: 12px;
            text-transform: uppercase; }

footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding-top: 60px;
  background-color: #2d2d2d; }
  footer div.col h4 {
    text-transform: uppercase;
    font-size: 15pt;
    font-weight: 500; }
  footer * {
    color: #ffffff; }

@media screen and (max-width: 1024px) {
  .banner .banner-bg img {
    height: auto;
    width: 180% !important; }
  .sideline {
    position: relative; }
    .sideline:before, .sideline:after {
      width: 15%; }
  .services .services-bg img {
    height: auto;
    width: 180% !important; }
  .cards {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .cards .card {
      width: 90%;
      margin-bottom: 10px; } }

@media screen and (max-width: 768px) {
  .banner .banner-img {
    padding-top: 100px;
    height: 200px; }
  .banner .banner-bg img {
    height: auto;
    width: 210% !important; }
  .banner .container .col {
    padding-top: 50px;
    padding-left: 30px; }
  .sideline {
    position: relative; }
    .sideline:before, .sideline:after {
      width: 15%; }
    .sideline:before {
      left: 0; }
    .sideline:after {
      right: 0%; }
  .services .services-bg img {
    height: auto;
    width: 210% !important; }
  .services .container .col {
    padding-top: 50px; }
  footer {
    -webkit-flex-direction: column;
            flex-direction: column; }
    footer .col iframe {
      text-align: center; }
  .abo .form-container {
    width: 95%;
    margin: auto; }
  .cards .card {
    width: 80%; } }

@media screen and (max-width: 570px) {
  .banner {
    height: 320px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
    .banner .banner-img {
      padding-top: 30px;
      height: 100px; }
    .banner .ultra {
      padding-top: -10px;
      margin-top: 0px;
      margin-left: -10px; }
    .banner p {
      text-align: center; }
    .banner h1 {
      font-size: 30pt;
      text-align: center; }
    .banner h2 {
      font-size: 13pt;
      text-align: center; }
    .banner .banner-bg {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
      .banner .banner-bg img {
        height: auto; }
    .banner .container .col {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      padding-top: 50px; }
  .services {
    height: auto/270;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
    .services p {
      text-align: center; }
    .services h1 {
      font-size: 30pt;
      text-align: center; }
    .services h2 {
      font-size: 13pt;
      text-align: center; }
    .services .services-bg {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
      .services .services-bg img {
        height: auto; }
    .services .container .col {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      padding-top: 50px; }
  header {
    position: relative;
    width: 100%; }
    header .xs-hide {
      display: none; }
    header .menu-container .menu .desktop-nav {
      display: none; }
    header .menu-container .burger span {
      -webkit-transform: none !important;
              transform: none !important; }
    header #nav-main {
      width: 100%;
      padding: 20px 0px 0px 0px; }
      header #nav-main div {
        padding: 0px 20px; }
    header input[type=checkbox]:checked + .menu-container .menu .burger {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
      header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(1) {
        -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px) !important;
                transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px) !important; }
      header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(2) {
        -webkit-transform: rotate(90deg) !important;
                transform: rotate(90deg) !important; }
      header input[type=checkbox]:checked + .menu-container .menu .burger span:nth-child(3) {
        -webkit-transform: scaleY(2.1) scaleX(0.9) translateY(0.5px) !important;
                transform: scaleY(2.1) scaleX(0.9) translateY(0.5px) !important; }
  .cards .card {
    width: 90%; } }

@media screen and (max-width: 320px) {
  .banner .banner-img {
    height: 100px; }
  .banner h1 {
    font-size: 30pt; }
  .banner .container .col {
    padding-left: 5px; }
  .services h1 {
    font-size: 30pt; } }


.full-width{
	width: 100%;
	max-width: initial;
}
.full-height{
	height: 100%;
	max-height: initial;
}

// Desktop
@mixin width-desktop(){
	.website-width{
		width: 97%;
		max-width: $breakpoint-desktop;
		margin: 0 auto;
		@include transition(width);	
		position: relative;
	}

	.website-half-width{
		width: 97%;
		max-width: #{$breakpoint-desktop / 2};
		margin: 0 auto;
		@include transition(width);	
	}
}

// Tablet_wide
@mixin width-tablet_wide(){
}

// Tablet
@mixin width-tablet(){
}

// Phone_wide
@mixin width-phone_wide(){
}

// Phone
@mixin width-phone(){
}
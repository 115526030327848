/************** COLORS **************/
/************ Base ************/
/* White */
$whiteColor:			#ffffff;
//$whiteTransparentColor:	url("../images/background-transparent-white.png");

/* Grey */
$greyColor:				#686868;

/* Black */
$blackColor:			rgba(45, 45, 45, 1);

/************ Graphic ************/
/* Header */
$bgHeaderMenu:			#222222;
$bgHeaderMenuSecondary:	#444444;
$bgHeaderMenuOpened:	#000000;
$bgTopContent:			#efefef;
$bgArticleHeaderTop:	#C5C5C5;

/* Main */
$bgSidebar:				#666666;

/* Tables */
$mainTableColor:		#E53C37;
$oddColor:				#efefef;

/* Footer */
$bgFooter:				#333333;

/************ Fonts ************/
/* Headings */
$h1Color:				#4D4D4D;
$h2Color:				#4D4D4D;
$h3Color:				#4D4D4D;
$h4Color:				#4D4D4D;

/* Paragraphs */
$mainParagraphColor:	#000000;

/* Links */
$whiteLink:				#FFFFFF;
$whiteVisitedLink:		#EFEFEF;
$greyLink:				#888888;

/************ Buttons ************/
$mainButtonColor: 		#555555;
$secondaryButtonColor: 	#333333;
$thirdButtonColor: 		#444444;

/************ Form ************/
$redForm: 				#DC3A35;
$errorForm: 			#e53c37;
$errorFieldForm: 		#eebfb6;
$warnForm: 				#ff9f2e;
$warnFieldForm: 		#f4e07d;
$greenForm: 			#008100;
$greenFieldForm: 		#aedca5;

/************ Specific ************/
$triompheMainColor: 	#E53C37;
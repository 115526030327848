$toY: 1.9;
$toX: 0.9;
$minusY: 0.5;
$minusX: 0.3;

@keyframes first_burger_bar {
  0%   {    transform: scaleY(1) scaleX(1)}
  17%  {    transform: scaleY($toY) scaleX($toX)}
  100%  {    transform: scaleY($toY) scaleX($toX)}
}
@keyframes second_burger_bar {
  0%   {    transform: scaleY(1) scaleX(1)}
  17%   {    transform: scaleY(1) scaleX(1)}
  33%  {    transform: scaleY($minusY) scaleX($toX) rotate(90deg)}
  100%  {    transform: scaleY($minusY) scaleX($toX) rotate(90deg)}
}
@keyframes third_burger_bar {
  0%   {    transform: scaleY(1) scaleX(1)}
  17%  {    transform: scaleY($toY) scaleX($toX)}
  100%  {    transform: scaleY($toY) scaleX($toX)}
}

@keyframes menu_sticky {
  0%   {    top: -300px}
  100%  {    top: 0px}
}

@keyframes menu_disappear {
  0%   {    top: 0px}
  100%  {    top: -300px}
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
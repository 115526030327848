$card-width: 90%;
$card-max-width: 450px;

// Desktop
@mixin card-desktop() {
	.cards {
		display: flex;
		justify-content: center;

		.star {
			font-size: 14pt;
			color: $color-white;
			vertical-align: text-top;
			font-weight: 300;
		}

		.card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			width: $card-width;
			max-width: $card-max-width;
			min-height: 600px;

			background-color: $blackColor;
			margin: 0px 20px;

			position: relative;
			overflow: hidden;

			@include transition();

			span.line-through {
				position: relative;
				color: $color-white;
				font-weight: 500;
				font-size: 12pt;

				&:after {
					position: absolute;
					content: ' ';
					left: -1px;
					top: 10px;
					height: 1px;
					width: 100%;
					background-color: $color-white;
				}
			}

			.promo {
				position: absolute;
				top: -30px;
				right: -220px;

				background-color: $color-promo;

				transform: rotate(45deg);
				padding: 60px 200px 0px 200px;
			}

			.corner {
				display: flex;
				flex-direction: row;
				justify-content: center;

				background-color: $color-primary;
				width: 100%;
				padding: 10px 0px;

				h3 {
					text-align: center;
					padding: 5px;
					margin: 5px;
				}
			}

			&:hover {
				transform: translateY(-10px);
			}

			* {
				outline: none;
				border: none;
			}

			button {
				background-color: $color-primary;
				border: none;
				padding: 12px 25px;
				font-size: 12px;
				font-weight: 700;
				border-radius: 20px;
				color: $color-white;
				text-transform: uppercase;
				cursor: pointer;
				@include transition();

				&:hover {
					background-color: darken($color: $color-primary, $amount: 8);
				}
			}

			.info {
				color: $color-white;
				font-size: 10px;
				align-self: flex-end;
				margin: 10px;
			}

			ul {
				list-style: none;
				padding-left: 0px;
				width: 100%;

				li {
					color: $color-white;
					text-align: center;
					padding: 20px 12px;
					border-bottom: 1px solid #494949;
					font-size: 12px;
					font-weight: 700;
					letter-spacing: 2.4px;
					text-transform: uppercase;

					h4 {
						color: $color-white;
					}

					&:nth-child(1) {
						font-size: 30pt;
						font-weight: 500;

						h4 {
							margin: 18px;
						}

						p {
							color: $color-white;
							font-size: 12px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}

// Tablet_wide
@mixin card-tablet_wide() {
	.cards {
		flex-direction: column;
		align-items: center;

		.card {
			width: 90%;
			margin-bottom: 10px;
		}
	}
}

// Tablet
@mixin card-tablet() {
	.cards {
		.card {
			width: 80%;
		}
	}
}

// Phone_wide
@mixin card-phone_wide() {
	.cards {
		.card {
			width: 90%;
		}
	}
}
// Phone
@mixin card-phone() {
}

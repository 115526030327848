// @type
// <type>Animations</type>

/* @doc
	<name>animation</name>
	<desc>Animates the element using keyframes.</desc>
	<example>
		.myButton{
			@include animation($example, .8s, 3s);
		}
	</example>
	<output>
		.myButton{
			-webkit-animation: example .8s 3s;
			-moz-animation: example .8s 3s;
			-ms-animation: example .8s 3s;
			-o-animation: example .8s 3s;
			animation: example .8s 3s;
		}
	</output>
*/
//	<mixin>
@mixin animation($name, $time:.5s, $duration: infinite) {
	@include prefixer(animation, $name $time $duration);
}
//	</mixin>

/*
	@doc
	<name>keyframes</name>
	<desc>Creates an animation to then use with the animation mixin.</desc>
	<example>@include keyframes(example){ ... };
	</example>
	<output>@keyframes example { ... };</output>
*/
//	<mixin>
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}
//	</mixin>

/*
	@doc
	<name>transition</name>
	<desc>Adds or removes a transition.</desc>
	<example>
		.myButton{
			@include transition(width, .5s, ease);
		}
	</example>
	<output>
		.myButton{
			-webkit-transition: width .5s ease;
			-moz-transition: width .5s ease; 
			...
		}
	</output>
*/
//	<mixin>
@mixin transition($property:all, $speed:.25s, $function:ease){
	-webkit-transition: $property $speed $function;
	-moz-transition: $property $speed $function;
	-ms-transition: $property $speed $function;
	-o-transition: $property $speed $function;
	transition: $property $speed $function;
}
//	</mixin>

/*
	@doc
	<name>noTransition</name>
	<desc>Removes a transition.</desc>
	<example>
		.myClass{
			@include noTransition;
		}
	</example>
	<output>
		.myClass{
			-webkit-transition: 0;
			-moz-transition: 0;
			-ms-transition: 0;
			-o-transition: 0;
			transition: 0;
		}
	</output>
*/
//	<mixin>		
@mixin noTransition{
	-webkit-transition: 0;
	-moz-transition: 0;
	-ms-transition: 0;
	-o-transition: 0;
	transition: 0;
}
//	</mixin>
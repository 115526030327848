// Breakpoints
$breakpoint-desktop: 		1200px;
$breakpoint-tablet_wide: 	1024px;
$breakpoint-tablet: 		768px;
$breakpoint-phone_wide:		570px;
$breakpoint-phone: 			320px;

// Padding
$padding-large:		60px;
$padding-medium:	30px;
$padding-small:		15px;

// Border
$width-border:		2px;

// Others
$width-website:		1200px;
$corner-radius:		5px;

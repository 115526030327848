// @type
// <type>Local</type>

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,900);

* {
  font-family: 'Montserrat', sans-serif;
  color: $blackColor;
}

html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: $blackColor;
  z-index: -1;
}

a {
  text-decoration: none;
}

#App {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;

  &.ready {
    opacity: 1;
  }

  .column {
    flex-direction: column;
  }
}

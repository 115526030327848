// Base Colors
$color-primary:		#e51737;
$color-secondary:	#888888;
$color-tertiary:	#57595b;
$color-white:		#ffffff;
$color-grey:		#808080;
$color-black:		#000000;
$color-promo:		#ffc754;

$color-bg:			#ececec;

/**
 * ! Don't edit underneath
 * - Shade of the base colors are generated
 * - Shade of the greys are generated
 * 
 * - All available colors & greys shown at the bottom
 **/

// Side Colors
$amount-color-lighten: 12%;
$color-primary-light:		lighten($color-primary,		$amount-color-lighten*1);
$color-secondary-light:		lighten($color-secondary,	$amount-color-lighten*1);
$color-tertiary-light:		lighten($color-tertiary,	$amount-color-lighten*1);

$color-primary-lighter:		lighten($color-primary,		$amount-color-lighten*2);
$color-secondary-lighter:	lighten($color-secondary,	$amount-color-lighten*2);
$color-tertiary-lighter:	lighten($color-tertiary,	$amount-color-lighten*2);

$color-primary-lightest:	lighten($color-primary,		$amount-color-lighten*3);
$color-secondary-lightest:	lighten($color-secondary,	$amount-color-lighten*3);
$color-tertiary-lightest:	lighten($color-tertiary,	$amount-color-lighten*3);

$amount-color-darken: 12%;
$color-primary-dark:		darken($color-primary,		$amount-color-darken*1);
$color-secondary-dark:		darken($color-secondary,	$amount-color-darken*1);
$color-tertiary-dark:		darken($color-tertiary,		$amount-color-darken*1);

$color-primary-darker:		darken($color-primary,		$amount-color-darken*2);
$color-secondary-darker:	darken($color-secondary,	$amount-color-darken*2);
$color-tertiary-darker:		darken($color-tertiary,		$amount-color-darken*2);

$color-primary-darkest:		darken($color-primary,		$amount-color-darken*3);
$color-secondary-darkest:	darken($color-secondary,	$amount-color-darken*3);
$color-tertiary-darkest:	darken($color-tertiary,		$amount-color-darken*3);

$amount-greys-ligthen: 9%;
$color-grey-light:			lighten($color-grey,		$amount-color-lighten*1);
$color-black-light:			lighten($color-black,		$amount-color-lighten*1);

$color-grey-lighter:		lighten($color-grey,		$amount-color-lighten*2);
$color-black-lighter:		lighten($color-black,		$amount-color-lighten*2);

$color-grey-lightest:		lighten($color-grey,		$amount-color-lighten*3);
$color-black-lightest:		lighten($color-black,		$amount-color-lighten*3);

$amount-greys-darken: 9%;
$color-white-dark:			darken($color-white,		$amount-color-darken*1);
$color-grey-dark:			darken($color-grey,			$amount-color-darken*1);

$color-white-darker:		darken($color-white,		$amount-color-darken*2);
$color-grey-darker:			darken($color-grey,			$amount-color-darken*2);

$color-white-darkest:		darken($color-white,		$amount-color-darken*3);
$color-grey-darkest:		darken($color-grey,			$amount-color-darken*3);


/*
 * Example of all available shades of colors
 */
.colors{
	color: $color-secondary-lightest;
	color: $color-secondary-lighter;
	color: $color-secondary-light;
	color: $color-secondary;
	color: $color-secondary-dark;
	color: $color-secondary-darker;
	color: $color-secondary-darkest;

	color: $color-primary-lightest;
	color: $color-primary-lighter;
	color: $color-primary-light;
	color: $color-primary;
	color: $color-primary-dark;
	color: $color-primary-darker;
	color: $color-primary-darkest;

	color: $color-tertiary-lightest;
	color: $color-tertiary-lighter;
	color: $color-tertiary-light;
	color: $color-tertiary;
	color: $color-tertiary-dark;
	color: $color-tertiary-darker;
	color: $color-tertiary-darkest;
}

/*
 * Example of all available shades of greys
 */
.greys{
	color: $color-white;
	color: $color-white-dark;
	color: $color-white-darker;
	color: $color-white-darkest;

	color: $color-grey-lightest;
	color: $color-grey-lighter;
	color: $color-grey-light;
	color: $color-grey;
	color: $color-grey-dark;
	color: $color-grey-darker;
	color: $color-grey-darkest;

	color: $color-black-lightest;
	color: $color-black-lighter;
	color: $color-black-light;
	color: $color-black;
}
// @type
// <type>Fonctions</type>

// Return true if $needle is found in $haystackList
@function foundInList($needle, $haystackList) {
	$found: false;

	@for $i from 1 through length($haystackList){
		$currentHaystackParam: nth($haystackList, $i);
		
		@if $currentHaystackParam == $needle{
			$found: true;
		}
	}

	@return $found;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
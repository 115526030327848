// Desktop
@mixin header-desktop(){
  header {
    height: $nav-height;
    background-color: $blackColor;
    z-index: 99;

    opacity: 1;

    transition: all 0.6s ease-in-out;

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;

      height: $nav-height - 20;

      background-color: $color-white;

      border-bottom: 2px solid $blackColor;

      animation: 0.6s ease-in-out menu_sticky;

      * {
        color: $blackColor!important;
      }

      
      .burger {
        background-color: $color-white!important;
        border-color: $blackColor!important;

        span {
          background-color: $blackColor!important;
        }
      }
      #nav-main {
        top: $nav-height - 20;
        border-left: 2px solid $blackColor;
      }
    }

    nav {
      ul {
        list-style: none;

        a {
          @include transition();
        }

        a.active {
          color: $color-primary!important;
        }
        &.side-nav {
          li {
            padding: 20px 0px;

            * {
              color: $color-white!important;
            }
          }
        }
      }
    }

    &.will-disappear {
      animation: 0.6s ease-in-out menu_disappear;
    }

    .link-24 {
      color: $color-white;
    }

    input[type=checkbox] {
      position: absolute;
      visibility: hidden;
    }
    
    input[type=checkbox]:checked+.menu-container {
      #nav-main{
        display: block;
        right: 0px;
        //box-shadow: 1px -1px 32px 8px #00000033;
      }

      .menu {
        .burger {
          transform: rotate(45deg);

          span {
            &:nth-child(1) {
              transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px);
            }
            
            &:nth-child(2) {
              transform: rotate(90deg);
            }
            
            &:nth-child(3) {
              transform: scaleY(2.1) scaleX(0.9) translateY(0.5px);
            }
          } 
        }
      }
		}

    #nav-main {
      position: absolute;
      width: 400px;
      right: -100%;
      top: $nav-height;
      background-color: $color-white;
      box-shadow: none;
      padding: 20px;

      z-index: 99;

      @include transition();
    }

    .menu-container {
      display: flex;

      width: 100%;
      height: 100%;

      .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 90%;
        max-width: 1500px;
        margin: auto;

        div {
          display: flex;
          flex-grow: 1;

          &:nth-child(1) {
            justify-content: flex-start;

            img {
              height: $nav-height - 30;
            }
          }
  
          &:nth-child(2) {
            justify-content: center;
          }
  
          &:nth-child(3) {
            justify-content: flex-end;
          }
        }

        nav {
          display: flex;
          flex-grow: 1;

          color: rgb(128, 128, 128);
          font-family: "open sans", sans-serif;
          font-size: 15px;
          font-weight: 400;

          ul {
            display: flex;
            flex-grow: 1;
            justify-content: space-evenly;

            padding-left: 0px;

            list-style-image: none;
            list-style-position: outside;
            list-style-type: none;

            li {
              display: flex;
              flex-grow: 1;
              justify-content: center;

              a {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 1.3px;
                text-transform: uppercase;
                color: $color-white;
              }

              a {
                cursor: pointer;
          
                @include transition();
          
                &:hover {
                  color: darken($color: $color-white, $amount: 10)
                }

                &:active {
                  color: darken($color: $color-primary, $amount: 10)
                }
              }
            }
          }
        }

        .burger {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          height: $nav-height / 3;
          width: $nav-height / 3;
          border: 2.7px solid $color-white;
          border-radius: 7px;
          cursor: pointer;
          @include transition();

          span {
            height: 3px;

            width: $nav-height / 6;
            background-color: $color-white;
            margin: 2px auto;
            @include transition();
          }

          &:hover {
            span {
              &:nth-child(1) {
                transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px);
              }
              
              &:nth-child(2) {
                transform: rotate(90deg);
              }
              
              &:nth-child(3) {
                transform: scaleY(2.1) scaleX(0.9) translateY(0.5px);
              }
            } 
          }
        }
      }
    }
  }

  .btn {
    background-color: $color-primary;
    border: none;
    padding: 12px 25px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 20px;
    color: $color-white;
    text-transform: uppercase;
    cursor: pointer;
    @include transition();

    &:hover {
      background-color: darken($color: $color-primary, $amount: 8)
    }
  }
}

// Tablet_wide
@mixin header-tablet_wide(){

}


// Tablet
@mixin header-tablet(){

}

// Phone_wide
@mixin header-phone_wide(){
  header {
    position: relative;
    width: 100%;

    .xs-hide {
      display: none;
    }

    .menu-container {
      .menu {
        .desktop-nav {
          display: none;   
        }
      }

      .burger {
        
        span {
          transform: none!important;
        }
      }
    }
    #nav-main { 
      width: 100%;
      padding: 20px 0px 0px 0px;

      div {
        padding: 0px 20px;
      }
    }

    input[type=checkbox]:checked+.menu-container {
      .menu {
        .burger {
          transform: rotate(45deg);

          span {
            &:nth-child(1) {
              transform: scaleY(2.1) scaleX(0.9) translateY(-0.5px)!important;
            }
            
            &:nth-child(2) {
              transform: rotate(90deg)!important;
            }
            
            &:nth-child(3) {
              transform: scaleY(2.1) scaleX(0.9) translateY(0.5px)!important;
            }
          }  
        }
      }
		}
  }
}

// Phone
@mixin header-phone(){
}
// Desktop
@mixin abo-desktop(){
  ::-moz-selection { background: $color-primary; color: $color-white; }
  ::selection { background: $color-primary; color: $color-white; }

  .abo {
    display: flex;
    flex-grow: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    background-color: $color-white;

    .success-bordered {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 4px solid green;
      height: 100px;
      width: 100px;
      border-radius: 100%;
    }

    .failed-bordered {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 4px solid $color-primary;
      height: 100px;
      width: 100px;
      border-radius: 100%;
    }

    .spinner {
      font-size: 20px;
      width: 1em;
      height: 1em;
      margin: 10px auto;
      border-radius: 50%;
      box-shadow: inset 0 0 0 .1em transparentize($color: $color-primary, $amount: 0.9);
    }
    
    .spinner i {
        position: absolute;
        clip: rect(0, 1em, 1em, .5em);
        width: 1em;
        height: 1em;
        animation: spinner-circle-clipper 1s ease-in-out infinite;
    }
    
    @keyframes spinner-circle-clipper {
        0% {
            transform: rotate(0deg);
        }
    
        100% {
            transform: rotate(180deg);
        }
    }
    
    .spinner i:after {
        position: absolute;
        clip: rect(0, 1em, 1em, .5em);
        width: 1em;
        height: 1em;
        content: '';
        animation: spinner-circle 1s ease-in-out infinite;
        border-radius: 50%;
        box-shadow: inset 0 0 0 .1em $color-primary;
    }
    
    @keyframes spinner-circle {
        0% {
            transform: rotate(-180deg);
        }
    
        100% {
            transform: rotate(180deg);
        }
    }

    .form-container {
      p {
        max-width: 80%;
      }
    }

    .form {
      max-width: 500px;
      display: flex;
      flex-direction: column;

      padding: 30px 20px 10px 20px;
      border: 2px solid $blackColor;

      .form-header {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $blackColor;
      }

      .form-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        padding-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid $blackColor;
      }

      .form-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px 0px 30px 0px;

        *:focus {
          outline: none;
        }

        label {
          font-size: 10pt;
          margin-bottom: 10px;
        }
      
        input {
          border: none;
          border-bottom: 2px solid $blackColor;
          border-radius: 0;
          font-size: 12pt;
          width: 100%;

          @include transition();

          &.invalid {
            border-color: $color-primary;
          }
        }

        .form-row {
          display: flex;
          flex-direction: row;
          margin: 20px 0px;
        }
        
        .group-col {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      }
    }

    div {
      display: flex;

      &.title {
        align-items: center;
        flex-direction: column;

        h3.strong {
          font-size: 30pt;
          font-weight: 700;
          letter-spacing: 2pt;
        }
      }
    }

    h3 {
      text-transform: uppercase;
      letter-spacing: 1.5pt;
    }
  }
}

// Tablet_wide
@mixin abo-tablet_wide(){

}

// Tablet
@mixin abo-tablet(){
  .abo {
    .form-container {
      width: 95%;
      margin: auto;
    }
  }
}

// Phone_wide
@mixin abo-phone_wide(){
	.abo {
    .form-container {
      width: 95%;
      margin: auto;
    }
  }
}

// Phone
@mixin abo-phone(){
	
}